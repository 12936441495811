<template>
    <div class="card card-f-h flex flex-col">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <ModalKeyHistory
            v-if="modalKeyHistoryVisible"
            :modalKeyHistory="modalKeyHistory"
            :selectedKey="selectedKey"
            @close="modalKeyHistory = false"
            @closed="modalKeyHistoryVisible = false"
        />

        <div class="flex items-end justify-between border-b border-gray-400 mb-3 pb-3">
            <router-link :to="{ name: 'keys' }">
                <el-button type="primary">
                    {{ $t('keys.go_back') }}
                </el-button>
            </router-link>
            <el-input v-model="quickSearch" suffix-icon="el-icon-search" class="w-72" :placeholder="$t('common.search')" clearable />
        </div>
        <KeysArchiveTable
            :data="keysData"
            :dataOrigin="keysDataOrigin"
            @handleKeyHistory="handleKeyHistory"
            @handleRestore="handleRestore"
            @handleDelete="handleDelete"
        />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
export default {
    components: {
        ModalKeyHistory:  () => import(/* webpackChunkName: "KeysArchive/ModalKeyHistory" */ './components/ModalKeyHistory'),
        KeysArchiveTable: () => import(/* webpackChunkName: "KeysArchive/KeysArchiveTable" */ './components/KeysArchiveTable'),
    },

    data() {
        return {
            noDataInformation:      false,
            modalEditHolderVisible: false,
            modalEditHolder:        false,
            modalEditKey:           false,
            modalEditKeyVisible:    false,
            modalAddKeyVisible:     false,
            modalAddKey:            false,
            modalKeyHistoryVisible: false,
            modalKeyHistory:        false,
            keysData:               [],
            keysDataOrigin:         [],
            quickSearch:            '',
        };
    },

    watch: {
        quickSearch() {
            this.filter();
        },
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('keys.keys'));
        this.$store.commit('setActiveMenuItem', '1-3');

        this.getAll();
    },

    beforeCreate() {
        this.$wait.start('loading.initial_keys_archive');
    },

    beforeDestroy() {
        axios.cancelAll();
    },

    methods: {
        async addKey() {
            this.modalAddKeyVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalAddKey = true;
        },

        async getAll() {
            this.$wait.start('loading');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/archived`, {
                requestId: 'justRequest',
            });

            this.keysData = Object.freeze(data);
            this.keysDataOrigin = Object.freeze(data);
            this.noDataInformation = data.length;
            this.$wait.end('loading');
            this.$wait.end('loading.initial_keys_archive');
        },

        async handleRestore(uuid) {
            this.$wait.start('loading');
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${uuid}/restore`);
            this.$notify.success({ title: 'Success' });
            this.$wait.end('loading');
            this.getAll();
        },

        handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/clients/keys/${uuid}/force_delete`,
                actionType: 'destroy',
            });
        },

        handleKeyHistory(scope) {
            this.selectedKey = scope.row;
            this.modalKeyHistoryVisible = true;
            this.modalKeyHistory = true;
        },

        filter() {
            this.keysData = this.keysDataOrigin.filter(key => (
                key.name.toLowerCase().includes(this.quickSearch.toLowerCase())
                || (key.description && key.description.toLowerCase().includes(this.quickSearch.toLowerCase()))
                || key.address.toLowerCase().includes(this.quickSearch.toLowerCase())
                || key.client.toLowerCase().includes(this.quickSearch.toLowerCase())
                || key.holder.toLowerCase().includes(this.quickSearch.toLowerCase())
            ));
        },
    },
};
</script>
<style scoped>
    .el-input{
        width: 18rem;
    }
    .el-button--mini {
        padding: 5px 5px;
    }
</style>
